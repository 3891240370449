/*******************************
         Site Overrides
*******************************/

.ui.pagination.secondary.menu a.active.item,
.ui.pagination.secondary.menu a.item:hover{
  background-color: #0d549b;
  border-radius: 50%;
  color: white;
}

.ui.pagination.secondary.menu a.item{
  color: #524e4e;
  display: flex;
  place-content: center;
}

.ui.pagination.secondary.menu a.item[type="nextItem"]:hover,
.ui.pagination.secondary.menu a.item[type="firstItem"]:hover,
.ui.pagination.secondary.menu a.item[type="lastItem"]:hover,
.ui.pagination.secondary.menu a.item[type="prevItem"]:hover{
  background-color: transparent;
  color: #524e4e;
}

.ui.pagination.secondary.menu a.item:focus{
  outline: 0; 
}