/*******************************
        User Overrides
*******************************/

.ui.dropdown.CustomDropdown.GameSelector {
        border-radius: 4px
}

.CustomDropdown.ui.dropdown:not(.button) > .default.text,.CustomDropdown.ui.dropdown:not(.button) > .text, .CustomDropdown.ui.default.dropdown:not(.button) > .text {
        color: white;
        width: 88%;
        font-weight: bold
}

.CustomDropdown.ui.dropdown .menu {
        border: 0;
        background-color: #0d549b;
        color: white;
        width: 100%;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
}

.ui.active.visible.dropdown.CustomDropdown.GameSelector {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

}

.CustomDropdown.ui.dropdown.selected, .CustomDropdown.ui.dropdown .menu .selected.item, .CustomDropdown.ui.dropdown .menu > .item {
        color: white
}