/*******************************
         Site Overrides
*******************************/
.ui.input input{
  text-align: center;
}

.ui.input.left input{
  text-align: left;
  padding: 0.875rem 1.25rem;
}

.ui.input.signin,
.ui.input.signin input{
  width: 100%;
}



.customToggle.ui.toggle.checkbox input:focus:checked ~ .box:before, .customToggle.ui.toggle.checkbox input:focus:checked ~ label:before {
 background: #dedede !important
}

.customToggle.ui.toggle.checkbox input:checked ~ .box:before, .customToggle.ui.toggle.checkbox input:checked ~ label:before {
 background-color: #dedede !important
}

.customToggle.ui.toggle.checkbox input:checked ~ label:after {
  box-shadow: 0px 1px 2px 0 #f44b48, 0px 0px 0px 1px #f44b48 inset
}

.customToggle.ui.toggle.checkbox label:after {
  background: #f44b48 linear-gradient(transparent, rgba(0, 0, 0, 0.05)) !important;
}

.customToggle.ui.toggle.checkbox input:checked ~ .box, .customToggle.ui.toggle.checkbox input:checked ~ label {
  color: #524e4e !important;
  padding-left: 0
}

.GameClusterContainer .ui.input {
  opacity: 0.45 !important;
}

.GameClusterContainer .ui.disabled.input {
  opacity: 1 !important;
}

.NameClusterInput input {
  color: #f44b48 !important;
  border: none;
  height: 1rem;
}

.UnitClusterInput input {
  color: #524e4e !important;
  border: none;
  height: 1rem;
}
