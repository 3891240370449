/*******************************
         Site Overrides
*******************************/
.ui.modal .actions{
  text-align: center;
  background-color: white;
  border-top: 0;
}
.ui.modal .actions button{
  width: '200px';
}