/*******************************
         Site Overrides
*******************************/
html {
  font-size: 16px;
  color: #524e4e;
}
a, a:hover {
  color: unset;
}

div.welcome {
  color: 'blue';
  text-transform: 'uppercase'; 
  fontSize: '1rem';
}

.ui.toggle.checkbox label,
.ui.toggle.checkbox input:focus:checked ~ .box, 
.ui.toggle.checkbox input:focus:checked ~ label{
  color: #524e4e !important;
}