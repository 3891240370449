/*******************************
         Site Overrides
*******************************/
.ui.primary.button {
  border-radius: 3px;
  font-weight: 300;
}
.ui.primary.button:hover {
  opacity: .7;
}

.button.ui.primary.button.hoverHideButton {
  width: auto !important;
}
